import * as React from "react";
import { useEffect } from "react";
import { PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/cozum/balgam-nedir/Content-image.webp";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Balgam Nedir, Neden Olur?",
        description:
          "Balgam Nedir, Neden Olur gibi soruların yanıtını içeriğimizi okuyarak öğrenmek için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Balgam Nedir, Neden Olur?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2022-01-27",
              "dateModified": "2022-01-27"
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Balgam Nedir?","acceptedAnswer":{"@type":"Answer","text":"Balgam solunum yollarını korumak için üretilir. İçeriğinde su, tuz, protein, beyaz kan hücreleri, alt solunum yollarından gelen atık hücreler ve antikorlar vardır. Balgam yani mukus yapışkan özelliğe sahiptir. Solunum yoluna giren toz, allerjenler, bakteri, sigara dumanı ve virüsler balgam tarafından tutulur ve akciğerlere temiz havanın girmesi sağlanır. "}},{"@type":"Question","name":"Balgamlı Öksürük Nedir?","acceptedAnswer":{"@type":"Answer","text":"Öksürük vücudun savunma mekanizmalarından biridir. Öksürük ve hapşırma yoluyla vücut aşırı mukus, balgam ve yabancı maddeleri dışarıya atar. Balgamlı öksürük olduğunda hırıltılı bir ses duyulur ve boğaza balgam gelir. Hasta boğazına gelen balgamı ya tükürükle beraber yutar yada tükürür. Çocuklar genellikle balgamı yutarlar, bunu beceremezlerse kusarlar."}},{"@type":"Question","name":"Çocuklarda Balgam Nasıl Atılır?","acceptedAnswer":{"@type":"Answer","text":"Özellikle sonbahar ve kış aylarında artan solunum yolu enfeksiyonları nedeniyle çocuklarda balgamlı öksürüğe sık rastlanır. Çocuklarda üst solunum yolu enfeksiyonları kolaylıkla alt solunum yolu enfeksiyonuna dönüşebilir. Bu durumda çocuklarda hırıltılı solunum ve balgamlı öksürük ortaya çıkar. Çocuklarda öksürük ve solunum sıkıntısı gibi durumlar genellikle geceleri artış gösterir. Bebekler ve küçük çocuklar balgam çıkarmayı beceremeyebilirler. Ya öksürdükten sonra yutarlar veya şiddetli öksürüğün etkisi ile kusarlar. Kusma çocukta geçici bir rahatlama sağlayabilir. Çocuklarda öksürük genellikle geçicidir. Ancak uzun süren balgamlı öksürük ve eşlik eden ateş, solunum sıkıntısı gibi belirtiler varsa doktora gitmek gerekir."}}]}
          `}
        </script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/cozum/balgam-nedir/Content-image.webp"
            alt="Boğazını tutan kadın görseli"
            width={1680}
            height={450}
          />
          <Container>
            <Heading>Balgam Nedir, Neden Olur?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-nedir"
                  title="Balgam Nedir?"
                >
                  Balgam Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-neden-olur"
                  title="Balgam Neden Olur?"
                >
                  Balgam Neden Olur?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-belirtileri-nelerdir"
                  title="Balgam Belirtileri nelerdir? "
                >
                  Balgam Belirtileri nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-turleri-nelerdir"
                  title="Balgam Türleri Nelerdir? "
                >
                  Balgam Türleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgamli-oksuruk-nedir"
                  title="Balgamlı Öksürük Nedir? "
                >
                  Balgamlı Öksürük Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-nasil-atilir"
                  title="Balgam Nasıl Atılır?"
                >
                  Balgam Nasıl Atılır?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#cocuklarda-balgam-nasil-atilir"
                  title="Çocuklarda Balgam Nasıl Atılır?"
                >
                  Çocuklarda Balgam Nasıl Atılır?
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p id="balgam-nedir" className="extra-bold">
            Balgam akciğerlerimizde bulunan bronşlar ve bronşioller tarafından
            üretilen mukus yapısında sıvı bir maddedir. Mukus üreten hücrelere
            Goblet hücreleri adı verilir. Normalde bronşlardan günlük 75-100 ml
            sekresyon salgılanır. Üretilen mukus solunum yollarını nemlendirir
            ve küçük yabancı maddelerin yakalanmasını sağlar. Bu miktardaki
            balgam farkında olmadan boğaza gelir ve tükürükle birlikte yutulur.
            Normal balgam sekresyonu öksürüğe neden olmaz.
          </p>
          <section className={styles.kidsHolder}>
            <Level>
              <h2 className="orange underline">Balgam Nedir?</h2>
              <p>
                Balgam solunum yollarını korumak için üretilir. İçeriğinde su,
                tuz, protein, beyaz kan hücreleri, alt solunum yollarından gelen
                atık hücreler ve antikorlar vardır. Balgam yani mukus yapışkan
                özelliğe sahiptir. Solunum yoluna giren toz, allerjenler,
                bakteri, sigara dumanı ve virüsler balgam tarafından tutulur ve
                akciğerlere temiz havanın girmesi sağlanır. Balgam normalde de
                akciğerlerimizde üretilir ancak akciğerlerimiz hastalandığında
                üretim artar. Fazla balgam öksürük yoluyla atılmaya çalışılır.
                Ağızda üretilen tükürük, geniz akıntısı ve kusma yoluyla mideden
                atılan sümüksü sıvı balgam olarak kabul edilmez.
                <br />
                <br />
                Balgam rengine göre farklı hastalıkları işaret edebilir:
              </p>
              <ul>
                <li className="blue">
                  <b>Berrak balgam:</b> Balgamın doğal rengi berraktır. Artış
                  gösterdiği durumlar genellikle alerjik hastalıklar ve soğuk
                  algınlığı gibi viral enfeksiyonlardan sonra görülen viral
                  bronşit ve zatürre dir.
                </li>
                <li className="blue">
                  <b>Yeşil-sarı balgam:</b> Akciğerlerde bir enfeksiyon olduğunu
                  gösterir. Kalın ve yapışkan kıvamdadır. Zatürre ve bronşit
                  hastalarında gözlenir.
                </li>
                <li className="blue">
                  <b>Kırmızı-pembe balgam (kanlı balgam):</b> Solunum yollarında
                  kanamaya bağlı öksürükle kanlı balgam çıkarılır. Akciğer
                  kanseri, pulmoner emboli, zatürre, kalp yetmezliği ve
                  tüberkülozda görülebilir. Balgamda çizgi şeklinde kan sıklıkla
                  rastlanılan bir durumdur. Üst solunum yolu enfeksiyonu ve akut
                  bronşit geçirenler çizgi şeklinde kanlı balgam çıkarabilirler.
                  Kırk yaşın üzerinde sigara içenlerde kanlı balgam gözlenirse
                  akciğer kanseri yönünden araştırmak gerekir.
                </li>
                <li className="blue">
                  <b>Kahverengi balgam:</b> Kanın beklemesi sonucu oluşur.
                  Genellikle pas rengini andırır. Pnömokonyoz (toza bağlı
                  akciğer hastalığı), bakteriyel zatürre, bakteriyel bronşit ve
                  akciğer apsesinde görülebilir.
                </li>
                <li className="blue">
                  <b>Siyah balgam:</b> Kalın ve yapışkan bir balgamdır. Sigara
                  içenlerde ve kokain kullananlarda görülebilir. Mantar
                  enfeksiyonlarında da siyah balgama rastlanabilir.
                </li>
                <li className="blue">
                  <b>Beyaz balgam:</b> Kronik obstrüktif akciğer hastalığı,
                  viral bronşit ve konjestif kalp yetmezliğinde görülür. Mide
                  reflüsü olan hastalarda kalın beyaz balgam çıkarabilirler.
                </li>
              </ul>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/balgam-nedir/img.webp"
                alt="Metroda öksüren kadın görseli"
                width={792}
                height={430}
              />
              <Level>
                <Heading id="oksurugun-nedenleri-nelerdir">
                  Balgam Neden Olur?
                </Heading>
              </Level>
              <p>
                Balgam üretimi normal akciğerde sürekli devam eder. Balgamın
                amacı solunum yoluna giren havayı filtrelemek ve temizlemektir.
                Toz, egzos dumanı, sigara gibi hava kirleticiler, allerjenler,
                bakteri ve virüsler akciğerde mukus üretimini artırırlar. Aşırı
                balgam hiçbir zaman normal kabul edilmez. Genel olarak balgam
                üretimini artıran hastalıklar şunlardır:
              </p>
              <ul>
                <li className="blue">
                  <b>Kronik Bronşit:</b> Bronşit bronş zarının
                  iltihaplanmasıdır. Enfeksiyonlar yanında sigara dumanı,
                  tozlar, kimyasal buharlar, egzos dumanları ve tarım ilaçları
                  bronşite neden olabilirler. Bronşitte aşırı mukus (balgam)
                  üretimi vardır. En az 3 ay süreli balgamlı öksürük vardır.
                </li>
                <li className="blue">
                  <b>KOAH:</b> Kronik obstrüktif akciğer hastalığı: Bu
                  hastalarda aşırı balgam üretimi vardır. Akciğerlerde
                  tıkanıklık nedeniyle gaz alışverişi bozulmuştur.
                </li>
                <li className="blue">
                  <b>Astım:</b> Kirli hava ve allerjenler solunum yollarını
                  uyararak aşırı mukus yapımına neden olurlar.
                </li>
                <li className="blue">
                  <b>Zatürre:</b> Bakteriler tarafından yapılan bu hastalıkta
                  yoğun balgam vardır.
                </li>
                <li className="blue">
                  <b>Konjestif kalp yetmezliği:</b> Kalbin kasılma yeteneği
                  bozulduğunda akciğerlerde göllenme olur.
                </li>
                <li className="blue">
                  <b>Kistik fibrozis:</b> Genellikle çocukluk döneminde
                  başlayan, kronik akciğer hastalığı ile birlikte sindirim
                  sistemini de tutan bir hastalıktır.
                </li>
                <li className="blue">
                  <b>Pulmoner ödem:</b> Akciğerlerde sıvı göllenmesidir.
                </li>
                <li className="blue">
                  <b>Pulmoner emboli:</b> Özellikle bacak damarlarından
                  akciğerlere pıhtı atması sonucu oluşur.
                </li>
                <li className="blue">
                  <b>Akciğer kanseri:</b> Uzun süren balgamlı veya kuru öksürük
                  akciğer kanserinin bir belirtisidir.
                </li>
                <li className="blue">
                  <b>Pnömokonyoz:</b> Özellikle kömür madeni gibi tozlu
                  ortamlarda çalışanlarda görülen aşırı balgamlı öksürükle
                  seyreden bir hastalıktır.
                </li>
                <li className="blue">
                  <b>Akciğer apsesi:</b> Akciğer içinde içi irinle dolu keseler
                  vardır. Balgamlı öksürüğe neden olabilir.
                </li>
                <li className="blue">
                  <b>Akciğerde mantar enfeksiyonu:</b> Özellikle kronik seyreden
                  mantarların sebep olduğu bir akciğer hastalığıdır. Bağışıklık
                  sistemi zayıf düşmüş kişilerde görülür.
                </li>
                <li className="blue">
                  <b>Tüberküloz:</b> Tüberküloz basili tarafında oluşturulan
                  kronik bir hastalıktır. Vücudun başka yerlerinde de
                  görülebilir.
                </li>
                <li className="blue">
                  <b>Amfizem:</b> Artan mukus üretimi ile beraber geçmeyen
                  öksürük görülür.
                </li>
                <li className="blue">
                  <b>Primer silier diskinezi:</b> Akciğerlerde bronşların
                  yüzeyini kaplayan silia adı verilen dokuların genetik olarak
                  kusurlu olması sonucu gelişir. Tekrarlayan akciğer
                  enfeksiyonları ve aşırı balgam üretimi vardır.
                </li>
                <li className="blue">
                  <b>Kas hastalıkları ve nörolojik hastalıklar:</b> Solunum
                  yollarındaki kaslar düzgün çalışmadığında veya hasta sürekli
                  hareketsiz yattığında aşırı mukus üretimi olabilir ve
                  akciğerler bunu dışarıya atmak da başarısız olabilir.
                </li>
              </ul>
              <p>
                Aşırı balgam üretimine neden olan çevresel faktörler ise
                şunlardır:
              </p>
              <ul>
                <li className="blue">
                  Sigara dumanı: Sigaranın solunum yolları üzerinde pek çok
                  önemli etkisi vardır. Normalde solunum yollarını döşeyen
                  kirpiksi yapıda hücre tabakası vardır. Bu hücre tabakası
                  mukusun boğaza doğru süpürülüp atılmasını sağlar. Sigara
                  dumanı bu hücrelerin yapısını bozar. Bu durumda mukus yeteri
                  kadar atılamadığından akciğerde birikir ve öksürüğe neden
                  olur. Sigara içmek ayrıca mukus üreten hücrelerin aşırı
                  çoğalmasına neden olur. Sigara içmek mevcut akciğer
                  hastalıklarını kötüleştirir ve akciğer kanserine yol açar.
                  Sigarayı bırakanlarda hasarlanan hücre tabakası yenilenir ve
                  mukus tekrar düzenli olarak temizlenir böylece öksürük azalır.
                </li>
                <li className="blue">Hava kirliliği</li>
                <li className="blue">Evcil hayvanlar</li>
                <li className="blue">Toz</li>
                <li className="blue">İşyerlerindeki toz ve dumanlar</li>
              </ul>
            </Level>
          </section>
          <section
            id="balgam-belirtileri-nelerdir"
            className={styles.kidsHolder}
          >
            <Level>
              <Level>
                <Heading>Balgam Belirtileri nelerdir?</Heading>
              </Level>
              <p>
                Artan balgam üretimi genellikle artan öksürükle birlikte
                seyreder. Kişide beraberinde şu belirtiler görülebilir:
              </p>
              <ul>
                <li className="blue">Nefes alırken hırıltı</li>
                <li className="blue">Hırıltılı öksürük</li>
                <li className="blue">Göğüs ağrısı</li>
                <li className="blue">Nefes darlığı</li>
                <li className="blue">Her öksürükte boğaza balgam gelmesi</li>
              </ul>
            </Level>
          </section>
          <section id="balgam-turleri-nelerdir" className={styles.kidsHolder}>
            <Level>
              <Level>
                <Heading>Balgam Türleri Nelerdir?</Heading>
              </Level>
              <p>
                Normal balgam renksiz yani saydamdır. Akciğerlerde hastalık
                yapan duruma göre balgamın rengi ve kıvamı değişebilir. KIvamına
                göre balgam:
              </p>
              <ul>
                <li className="blue">
                  <b>Seröz balgam:</b> Pembe, köpüklü ve su gibidir. Akciğer
                  ödemi ve bazı akciğer kanseri türlerinde görülebilir. Akciğer
                  ödeminde hasta balgamla boğulur gibi olur, yatamaz.
                </li>
                <li className="blue">
                  <b>Mukoid balgam:</b> Renksiz şeffaf ve jelatin kıvamındadır.
                  Sigara içenlerde bu tür balgam görülür. Ayrıca astım,
                  tüberküloz, amfizem, zatürre başlangıcı, kronik bronşit ve
                  akciğer tümörü vakalarında da bu tür balgam olabilir.
                </li>
                <li className="blue">
                  <b>Pürülan (iltihabi) balgam:</b> Genellikle sarı veya yeşil
                  renktedir. İçinde mukus, bakteri ve iltihap hücreleri bulunur.
                  Pürülan balgam zatürre, kronik bronşit, akciğer absesi,
                  tüberküloz ve kistik fibrozis gibi hastalıklarda görülür. Bazı
                  kimyasalların solunması, sigara dumanı ve alerjenler pürülan
                  balgam yapabilir.{" "}
                </li>
              </ul>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/balgam-nedir/man-using-tissue-in-hand-cover-your-mouth-when-cough-and-sneezing-while-feeling-sick-high-fever_t20_.webp"
                alt="Hapşuran/öksüren erkek görseli"
                width={792}
                height={430}
              />
            </Level>
          </section>
          <section id="balgamli-oksuruk-nedir" className={styles.kidsHolder}>
            <Level>
              <Level>
                <Heading>Balgamlı Öksürük Nedir?</Heading>
                <p>
                  Öksürük vücudun savunma mekanizmalarından biridir. Öksürük ve
                  hapşırma yoluyla vücut aşırı mukus, balgam ve yabancı
                  maddeleri dışarıya atar. Balgamlı öksürük olduğunda hırıltılı
                  bir ses duyulur ve boğaza balgam gelir. Hasta boğazına gelen
                  balgamı ya tükürükle beraber yutar yada tükürür. Çocuklar
                  genellikle balgamı yutarlar, bunu beceremezlerse kusarlar.
                </p>
              </Level>
            </Level>
          </section>
          <section id="balgam-nasil-atilir" className={styles.kidsHolder}>
            <Level>
              <Level>
                <Heading>Balgam Nasıl Atılır?</Heading>
                <p>
                  Vücutta normalde üretilen balgamı solunum yolları boğaza doğru
                  süpürür ve farketmeden yutarız. Balgam üretimi çeşitli
                  nedenlerden dolayı aşırı miktarda olduğunda öksürük
                  mekanizması devreye girer. Öksürük boğaz, sinüsler ve
                  bronşları rahatsız eden bir şey olduğunda ortaya çıkan savunma
                  sisteminin bir tepkisidir. Aşırı balgam oluşumu sekresyonların
                  temizlenmesi amacıyla öksürüğü tetikler. Öksürük sırasında
                  akciğerler güçlü bir şekilde kasılır ve gürültülü bir şekilde
                  öksürük meydana gelir.
                </p>
                <p>
                  Yetişkinlerde balgam atılımını kolaylaştırmak için evde
                  yapılabilecek bazı uygulamalar vardır. Bunlar:
                </p>
                <ul>
                  <li className="blue">
                    Bol su içmek: Su balgamın yumuşamasını sağlar.
                  </li>
                  <li className="blue">
                    Göğüs bölgesine okaliptüs yağı sürmek
                  </li>
                  <li className="blue">
                    Havayı nemli tutmak: Kuru hava balgamın katılaşmasına yol
                    açarak atılmasını zorlaştırır. Balgamlı öksürük olduğunda
                    hava nemlendiricisi kullanmak veya kalorifer peteklerinin
                    üzerine su yerleştirmek havayı nemlendirerek balgam
                    atılmasını kolaylaştırır.
                  </li>
                  <li className="blue">
                    Buhar solumak: Bir buhar makinesinden buhar solumak veya
                    buhar makinesi yoksa bir kase sıcak suyun içine okaliptus
                    veya nane yağı damlatarak solumak faydalı olabilir.
                  </li>
                  <li className="blue">
                    Limonlu su içmek: Limonlu suyun balgam söktürücü etkisi
                    vardır.
                  </li>
                  <li className="blue">
                    Bal yemek: Bal doğal bir balgam söktürücüdür. 2 yaşından
                    küçük çocuklar için bal, uygun bir gıda değildir.
                  </li>
                  <li className="blue">
                    Sigarayı bırakın ve sigara içilen ortamlarda bulunmayın.
                  </li>
                  <li className="blue">
                    Klimalarınızın filtrelerini düzenli olarak temizleyin.
                  </li>
                  <li className="blue">
                    Yaşadığınız ortamda aşırı nem ve küf varsa bundan kaçının.
                    Balgamlı öksürüğünüzün nedeni bu durum olabilir.
                  </li>
                </ul>
              </Level>
            </Level>
          </section>
          <section
            id="cocuklarda-balgam-nasil-atilir"
            className={styles.kidsHolder}
          >
            <Level>
              <Level>
                <Heading>Çocuklarda Balgam Nasıl Atılır?</Heading>
                <p>
                  Özellikle sonbahar ve kış aylarında artan solunum yolu
                  enfeksiyonları nedeniyle çocuklarda balgamlı öksürüğe sık
                  rastlanır. Çocuklarda üst solunum yolu enfeksiyonları
                  kolaylıkla alt solunum yolu enfeksiyonuna dönüşebilir. Bu
                  durumda çocuklarda hırıltılı solunum ve balgamlı öksürük
                  ortaya çıkar. Çocuklarda öksürük ve solunum sıkıntısı gibi
                  durumlar genellikle geceleri artış gösterir. Bebekler ve küçük
                  çocuklar balgam çıkarmayı beceremeyebilirler. Ya öksürdükten
                  sonra yutarlar veya şiddetli öksürüğün etkisi ile kusarlar.
                  Kusma çocukta geçici bir rahatlama sağlayabilir. Çocuklarda
                  öksürük genellikle geçicidir. Ancak uzun süren balgamlı
                  öksürük ve eşlik eden ateş, solunum sıkıntısı gibi belirtiler
                  varsa doktora gitmek gerekir.
                </p>
                <p>
                  Çocuklar bir yıl içinde pek çok defa öksürük sorunu
                  yaşayabilirler. Çocuğun genel durumu iyi ve eşlik eden ciddi
                  bir belirti yoksa evde alınabilecek basit tedbirlerle çocuk
                  rahatlatılabilir. Oda havasını nemlendirerek veya buhar
                  solutarak çocuğunuzun balgamını rahat atmasını
                  sağlayabilirsiniz. Ilık olarak tüketilen su ve bitki çayları
                  balgamın yumuşamasını ve rahat atılmasını sağlayabilir. Sütün
                  balgamı koyulaştırdığı bilinmektedir. Bu nedenle hastalık
                  dönemlerinde mümkünse süt tüketimini azaltabilirsiniz.
                  Özellikle gece öksürüğü çocuğun uykusunu böldüğü ve
                  dinlenememesine neden olduğu için yatmadan önce öksürüğe iyi
                  gelen bir ürün kullanabilirsiniz.
                </p>
              </Level>
            </Level>
          </section>

          <section className={styles.kidsHolder}>
            <Level>
              <Level>
                <Heading>Bisolnatur ile Öksürüğünü Rahatlat!</Heading>
                <div className={classNames("flexbox", styles.productHolder)}>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/product-bar-bisolduo-surup.png"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={282}
                  />
                  <div className={styles.content}>
                    <p>
                      Bisolnatur doğal içerikli bir öksürük şurubudur.
                      Bisolnatur bir ilaç değildir. Hem çocuklarda hemde
                      yetişkinlerde kuru ve balgamlı öksürüğü rahatlatır.
                      Sonbahar mevsimi ile birlikte grip ve solunum sistemi
                      hastalıklarında artış yaşanır. Bu enfeksiyonlara öksürük
                      sıklıkla eşlik eder. Arka arkaya öksürmek hem yetişkinleri
                      hemde çocukları yorar. Bisolnatur koruyucu, renklendirici
                      ve katkı maddesi içermez. Hoş bir tada sahip olan
                      bisolnatur 1 yaş üzerindeki çocuklarda ve yetişkinlerde
                      rahatlıkla kullanılabilir. Hamileler ve emziren annelerin
                      kullanmadan önce hekime danışmaları gerekir.
                      <br />
                      <br />
                    </p>
                    <p>
                      Bisolnatur boğazdan geçen balgamı nemlendirip yumuşatarak
                      kolaylıkla atılmasını sağlar.
                    </p>
                  </div>
                </div>
              </Level>
            </Level>
          </section>
          <section className={styles.kidsHolder}>
            <Level>
              <Level>
                <Level>
                  <Heading>Bisolnatur Nasıl Kullanılır?</Heading>
                </Level>
              </Level>
              <p>
                Bisolnaturu kullanmadan önce kullanma kılavuzunu dikkatle
                okuyunuz. Şişeyi kullanmadan önce iyice çalkalayın. Ölçü olarak
                kutunun içinden çıkan kaşığı kullanın. Bir doz bir ölçü
                kaşığıdır (<u>1 ölçü kaşığı=1 tatlı kaşığı</u>) . Bisolnatur
                günlük dozları şu şekildedir:
              </p>
              <ul>
                <li className="blue">
                  <b>1-6 yaş arasındaki çocuklarda</b>, 1 ölçü kaşığı (5 ml)
                  olmak üzere günde iki doz
                </li>
                <li className="blue">
                  <b>6 yaş ve üzerindeki çocuklarda</b> 2 ölçü kaşığı (10 ml)
                  olmak üzere günde iki doz
                </li>
                <li className="blue">
                  <b>Yetişkinlerde</b> ise 2 ölçü kaşığı (10 ml) olmak üzere
                  günde iki veya üç doz (Gerekli görülen durumlarda, ürün
                  aralıklarla günde 4 defaya kadar kullanılabilir.)
                </li>
              </ul>
              <p>
                Hamileler ve emzirenler hekime danışarak ürünü kullanabilirler.
                Sizin veya çocuğunuzun öksürüğü bir haftadan uzun sürüyorsa,
                kanlı balgamlı öksürük varsa, ateş eşlik ediyorsa hekime
                başvurunuz.
                <br />
                <br />
              </p>
            </Level>
          </section>
          <section className={styles.kaynakca}>
            <Level>
              <Level>
                <Heading id="kaynakca">Kaynakça</Heading>
              </Level>
            </Level>
            <p>
              1- https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5796802/
            </p>
            <p>
              2- https://www.ncbi.nlm.nih.gov/books/NBK359/
            </p>
            <p>
              3- https://www.lung.org/blog/lungs-mucus
            </p>
            <p>
              4- https://oxfordmedicine.com/view/10.1093/med/9780199600830.001.0001/med-9780199600830-chapter-119
            </p>
            <p>
              5-
              https://www.nursingtimes.net/clinical-archive/respiratory-clinical-archive/the-physiology-of-mucus-and-sputum-production-in-the-respiratory-system-10-06-2003/
            </p>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2022-01-27</p>
          <p>Son güncellenme Tarihi: 2022-01-27</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughPage;
