// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--swwWG";
export var breakme = "styles-module--breakme---7u7p";
export var content = "styles-module--content--tl4jn";
export var header = "styles-module--header--EYqpP";
export var holder = "styles-module--holder--v2v2d";
export var howHolder = "styles-module--howHolder--A4P17";
export var image = "styles-module--image--9-rzP";
export var kaynakca = "styles-module--kaynakca--bDfd1";
export var kidsHolder = "styles-module--kidsHolder--IR5qP";
export var listHolder = "styles-module--listHolder--ExZRW";
export var productHolder = "styles-module--productHolder--Thdmz";
export var rightCoughHolder = "styles-module--rightCoughHolder--Y4EMD";
export var typesHolder = "styles-module--typesHolder--hEjQC";
export var whatHolder = "styles-module--whatHolder--kaO0p";
export var whyHolder = "styles-module--whyHolder--1MUIr";